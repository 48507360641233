import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/chateaudemorey/src/components/Layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><strong parentName="h1">{`Location de salles Nancy Metz`}</strong></h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/810e3e45a087fdb16eb9a8b786cc7317/a878e/LocationSalles3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.400000000000002%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsSAAALEgHS3X78AAABPElEQVQY0wExAc7+AGtUNVpAHFk7FmFCG2dGHmxLJGxJI35oTYNxVpKLc6afkJ+BWqSGabKll9zTwuvcx5RdLcOkf/Dl0vPl0gB4Z0tdRSNYPBdcPRhfPhhoRx9hQBt9aE9zY0mCd1+lmoqjiWajg2G0oY7Rxbnm2cmUWijMqoX169r17uIAdGhRXksuZFAzbVg/cFg9blQ1Z0wuhXdldm9lYVdNh3homX5hpoxvlnJlhl1WxLarwqaS3c7A9vPt7urmAHx5cW5iU3NpW3FiUHNnWod+dqGcl3hiT6iimJeWi5qKfIhrUqWTga+ckLCbjNLNzK6noru5tsnDv9DLyQBiVEqBenS9ytJpYFNLNR9eUEGHeW14ZVSclpClpqSrqqmknZihlYWqmIyql4fCwsGAbFeAblqgnZqfoqUFwpoNydy6XgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Location de Salles mariages Nancy Metz",
            "title": "Location de Salles mariages Nancy Metz",
            "src": "/static/810e3e45a087fdb16eb9a8b786cc7317/00d43/LocationSalles3.png",
            "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/63868/LocationSalles3.png 250w", "/static/810e3e45a087fdb16eb9a8b786cc7317/0b533/LocationSalles3.png 500w", "/static/810e3e45a087fdb16eb9a8b786cc7317/00d43/LocationSalles3.png 1000w", "/static/810e3e45a087fdb16eb9a8b786cc7317/aa440/LocationSalles3.png 1500w", "/static/810e3e45a087fdb16eb9a8b786cc7317/e8950/LocationSalles3.png 2000w", "/static/810e3e45a087fdb16eb9a8b786cc7317/a878e/LocationSalles3.png 2048w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Location de Salles mariages Nancy Metz`}</strong></p>
    <h2>{`Un `}<strong parentName="h2">{`lieu de réception`}</strong>{` en `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <h3>{`Organisez votre `}<strong parentName="h3">{`événement`}</strong>{` entre `}<strong parentName="h3">{`Nancy et Metz`}</strong></h3>
    <p>{`Le `}<strong parentName="p">{`Château de Morey`}</strong>{` accueil vos réceptions en ses lieux authentiques et chaleureux.
Faites de vos événements des moments uniques. `}</p>
    <p>{`3 salles : 1 salle pour 60/65 adultes – 1 salle pour 15/20 enfants – 1 salle de danse
Un parc arboré de plus d’un hectare, avec des arbres d’une centaine d’années et des oiseaux qui chantent vous transporterons ailleurs.`}</p>
    <ButtonInternal to="/location-salles-nancy-metz/brochure/" mdxType="ButtonInternal">Demandez votre Brochure</ButtonInternal>
    <h2><strong parentName="h2">{`Mariages, réceptions et cocktail`}</strong>{` `}<strong parentName="h2">{`proche de Nancy`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b4e4e518b5a879bf4410b0cf46bc522/c08c5/chateau_de_morey_mariage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.39999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7UxSpAk//8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAAhEh/9oACAEBAAEFAhsFuLF//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABkQAAIDAQAAAAAAAAAAAAAAAAABESAxUf/aAAgBAQAGPwJyPtNZ/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERITFBcWH/2gAIAQEAAT8hfw6GdFDlkVkYh6Vw/9oADAMBAAIAAwAAABAE7//EABcRAQADAAAAAAAAAAAAAAAAAAAhQVH/2gAIAQMBAT8QpOP/xAAXEQADAQAAAAAAAAAAAAAAAAAAESFR/9oACAECAQE/EFR4P//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExUWFxkf/aAAgBAQABPxAS42+MKIilLrWsUVNyPvFoKnTkW014y/AdKGf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Seminaires d'entreprises nancy et metz",
            "title": "Seminaires d'entreprises nancy et metz",
            "src": "/static/2b4e4e518b5a879bf4410b0cf46bc522/c08c5/chateau_de_morey_mariage.jpg",
            "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/0479a/chateau_de_morey_mariage.jpg 250w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/41099/chateau_de_morey_mariage.jpg 500w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/c08c5/chateau_de_morey_mariage.jpg 640w"],
            "sizes": "(max-width: 640px) 100vw, 640px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Seminaires d’entreprises nancy et metz`}</strong></p>
    <h3>{`Fêtez votre union ? Votre `}<strong parentName="h3">{`anniversaire`}</strong>{` ? `}<strong parentName="h3">{`Brunch`}</strong>{` en famille ? `}<strong parentName="h3">{`proche de Metz et Nancy`}</strong></h3>
    <p>{`Émerveillez-vous, vous et vos invités en passant une `}<strong parentName="p">{`soirée`}</strong>{` au `}<strong parentName="p">{`Château de Morey`}</strong>{`. Les murs en pierres traditionnelles de nos `}<strong parentName="p">{`salles`}</strong>{` vous plongeront dans une ambiance chic et épurée.
Entre `}<strong parentName="p">{`Nancy et Metz`}</strong>{`, votre événement peut prendre tout type de style, médiéval, épuré, moderne, champêtre, bohème… Adaptez nos lieux selon vos goûts.
Cependant, il est toujours sympa de rappeler qu’un endroit de `}<strong parentName="p">{`réception`}</strong>{` charmant engendre moins de frais de décoration. `}</p>
    <p>{`Nous recevons, en `}<strong parentName="p">{`Lorraine`}</strong>{`, des `}<strong parentName="p">{`mariages`}</strong>{` depuis plus de 20 ans, alors c’est avec plaisir que nous vous accompagnerons lors de l’organisation de votre `}<strong parentName="p">{`événement`}</strong>{`.
Nous sommes familiarisés avec ce milieu depuis des années, cependant nous continuons à être émerveillé devant tous les `}<strong parentName="p">{`mariages`}</strong>{` que nous recevons, grâce à votre authenticité. `}</p>
    <p>{`En `}<strong parentName="p">{`Meurthe-et-Moselle`}</strong>{`, transformer son événement en souvenir unique est possible. Nous disposons d’un parc bucolique de plus d’un hectare, cela donne alors de l’espace lors de vos `}<strong parentName="p">{`réceptions`}</strong>{`. Où vous pourrez voir vos enfants courir entre nos cèdres du Liban, vos proches trinquant en regardant la vue exceptionnelle sur la vallée…
Appréciez un moment en famille dans une grande demeure chaleureuse qui vous donnera le sentiment de recevoir vos proches chez vous. `}</p>
    <ButtonInternal to="/location-salles-nancy-metz/mariage-clef-main-a-nancy/" mdxType="ButtonInternal">Mariages clef en main</ButtonInternal>
    <ButtonInternal to="/location-salles-nancy-metz/mariages/" mdxType="ButtonInternal">Les mariages</ButtonInternal>
    <h2>{`Vos `}<strong parentName="h2">{`événements professionnels`}</strong>{` à quelques minutes de `}<strong parentName="h2">{`Nancy et Metz`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/810e3e45a087fdb16eb9a8b786cc7317/a878e/LocationSalles3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.400000000000002%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsSAAALEgHS3X78AAABPElEQVQY0wExAc7+AGtUNVpAHFk7FmFCG2dGHmxLJGxJI35oTYNxVpKLc6afkJ+BWqSGabKll9zTwuvcx5RdLcOkf/Dl0vPl0gB4Z0tdRSNYPBdcPRhfPhhoRx9hQBt9aE9zY0mCd1+lmoqjiWajg2G0oY7Rxbnm2cmUWijMqoX169r17uIAdGhRXksuZFAzbVg/cFg9blQ1Z0wuhXdldm9lYVdNh3homX5hpoxvlnJlhl1WxLarwqaS3c7A9vPt7urmAHx5cW5iU3NpW3FiUHNnWod+dqGcl3hiT6iimJeWi5qKfIhrUqWTga+ckLCbjNLNzK6noru5tsnDv9DLyQBiVEqBenS9ytJpYFNLNR9eUEGHeW14ZVSclpClpqSrqqmknZihlYWqmIyql4fCwsGAbFeAblqgnZqfoqUFwpoNydy6XgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Location de Salles mariages Nancy Metz",
            "title": "Location de Salles mariages Nancy Metz",
            "src": "/static/810e3e45a087fdb16eb9a8b786cc7317/00d43/LocationSalles3.png",
            "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/63868/LocationSalles3.png 250w", "/static/810e3e45a087fdb16eb9a8b786cc7317/0b533/LocationSalles3.png 500w", "/static/810e3e45a087fdb16eb9a8b786cc7317/00d43/LocationSalles3.png 1000w", "/static/810e3e45a087fdb16eb9a8b786cc7317/aa440/LocationSalles3.png 1500w", "/static/810e3e45a087fdb16eb9a8b786cc7317/e8950/LocationSalles3.png 2000w", "/static/810e3e45a087fdb16eb9a8b786cc7317/a878e/LocationSalles3.png 2048w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Location de Salles mariages Nancy Metz`}</strong></p>
    <h3><strong parentName="h3">{`Team Building`}</strong>{` ? `}<strong parentName="h3">{`Conférence`}</strong>{` ? `}<strong parentName="h3">{`Soirée d’entreprise`}</strong>{` ? `}<strong parentName="h3">{`Séminaire résidentiel`}</strong>{` ?`}</h3>
    <p>{`Des formules spéciales entreprises sont proposées au `}<strong parentName="p">{`Château de Morey`}</strong>{`.
En `}<strong parentName="p">{`Lorraine`}</strong>{`, profitez de ce lieu pour informer, remercier, encourager votre personnel, vos partenaires…`}</p>
    <p>{`Appréciez un moment professionnel, dans un cadre chaleureux entre `}<strong parentName="p">{`Nancy et Metz`}</strong>{`. Joigniez l’utile à l’agréable, nous nous occupons de l’organisation de vos `}<strong parentName="p">{`événements`}</strong>{` : `}</p>
    <ul>
      <li parentName="ul">{`Des menus qui conviennent à tous les goûts et tous les budgets`}</li>
      <li parentName="ul">{`Des mises en place adaptées à vos réunions `}</li>
      <li parentName="ul">{`Une liste de prestataires pour divertir vos équipes `}</li>
    </ul>
    <p>{`Du `}<strong parentName="p">{`cocktail de soirée`}</strong>{` au `}<strong parentName="p">{`séminaire résidentiel`}</strong>{`, nous vous recevons avec grand plaisir. Nos 5 `}<strong parentName="p">{`chambres d’hôtes`}</strong>{` vous permettent de bénéficier d’hébergement si besoin lors de votre `}<strong parentName="p">{`événement`}</strong>{`. `}</p>
    <p>{`Le charme du `}<strong parentName="p">{`Château`}</strong>{` regroupe aussi calme et détente avec son parc arboré de plus d’un hectare. Un espace confort, en pleine nature tout en étant proche de `}<strong parentName="p">{`Nancy et Metz`}</strong>{`. `}</p>
    <ButtonInternal to="/location-salles-nancy-metz/brochure/" mdxType="ButtonInternal">Recevez votre devis</ButtonInternal> 
    <h2>{`Nos événements tout proche de `}<strong parentName="h2">{`Nancy`}</strong></h2>
    <h3>{`Nous aussi, on aime faire la fête…`}</h3>
    <p>{`Partager un bon moment avec vous, est un plaisir pour nous. Chaque année, nous organisons une dizaine d’événements. Passez une soirée animée entre `}<strong parentName="p">{`Nancy et Metz`}</strong>{`. `}</p>
    <p><strong parentName="p">{`Nouvel An, Saint Valentin, Brunch, Dîner dans le noir, Dîner spectacle`}</strong>{`…
En `}<strong parentName="p">{`Lorraine`}</strong>{`, appréciez un moment festif, accompagné de votre famille, amis, compagnons ou de vos enfants. Nos `}<strong parentName="p">{`événements`}</strong>{` plaisent à tous les âges !
Le large espace dont dispose le `}<strong parentName="p">{`Château de Morey`}</strong>{` vous permet de passer un moment convivial et chaleureux pour toutes les occasions. `}</p>
    <p>{`C’est un plaisir de vous faire découvrir nos partenaires lors de ces événements, les douces voix de nos chanteurs préférés, les mets de nos traiteurs favoris… `}</p>
    <p>{`Vous pouvez rejoindre notre communauté pour être informé de chacun de nos événements. `}</p>
    <ButtonInternal to="/newsletter/" mdxType="ButtonInternal">Rejoignez notre newsletter</ButtonInternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      